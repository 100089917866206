#main {
  height: 100vh;
  background-image: url('../images/logo/rogline_large.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #main {
    height: 80vh;
  }
}
  
h1, h3 {
  color: #1aecec;
}
  
#about {
  padding: 10px;
  text-align: left;
  color: floralwhite;
  background-color: rgba(2, 15, 31);
  background-size: cover;
}


#offer {
  padding: 10px;
  text-align: left;
  color: floralwhite;
  background-color: rgba(2, 15, 31);
}

#contact {
  padding: 20px;
  text-align: center;
  color: floralwhite;
  background-color: rgba(2, 15, 31);
}
